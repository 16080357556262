import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Contact from "../components/sections/contact"

const IndexPage = () => (
  <Layout>
    <Seo title="Benalcons" />
    <Contact />
  </Layout>
)

export default IndexPage
